import React, { Component } from 'react';
import Link from '../utils/link';
import { Logo, Phone } from './icons';

class Header extends Component {
  state = {
    offCanvas: false,
    activeDropdown: null,
    activeOffCanvasPanel: null,
  };

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false });
  };

  _hideAll = () => {
    this.setState({
      offCanvas: false,
      activeDropdown: null,
      activeOffCanvasPanel: null,
    });
  };

  _toggleOffCanvas = () => {
    this.setState((prevState) => ({
      offCanvas: !prevState.offCanvas,
      activeOffCanvasPanel: null,
    }));
  };

  render() {
    const { offCanvas, activeDropdown, activeOffCanvasPanel } = this.state;
    const { data } = this.props;

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    };
    let dropProps = {
      onClick: this._hideAll,
      activeClassName: 'active',
    };

    let headerClass = 'header';
    if (activeDropdown) headerClass += ' header--dropdown';
    if (activeOffCanvasPanel) headerClass += ' header--off-canvas-panel';
    if (offCanvas) headerClass += ' header--off-canvas';
    if (this.props.pageContext.header) headerClass += ` header--${this.props.pageContext.header}`;

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Wealth Street Property' className='header__logo' {...props}>
              <Logo />
            </Link>
            <nav className='header__nav'>
              <ul>
                {data.wp.siteOptions.acf.headerMenu.map((item, index) => (
                  <li
                    key={index}
                    className={activeDropdown === index ? 'active' : ''}
                    onMouseEnter={() => this.setState({ activeDropdown: index })}
                    onMouseLeave={() => this.setState({ activeDropdown: null })}
                  >
                    <Link to={item.link.url} {...props}>
                      {item.link.title}
                    </Link>
                    {item.subMenu && (
                      <div className='header__dropdown'>
                        <ul>
                          {item.subMenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Link to={subItem.link.url} {...dropProps}>
                                {subItem.link.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className='header__button'>
              <Link to='tel:+61288609211' className='btn btn--forest'>
                <Phone />
                <span>1800 Wealth</span>
              </Link>
              <button
                onClick={this._toggleOffCanvas}
                className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}
              >
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                {data.wp.siteOptions.acf.headerMenu.map((item, index) => (
                  <li key={index} onClick={() => this.setState({ activeOffCanvasPanel: index })}>
                    {item.link.title}
                  </li>
                ))}
              </ul>
            </nav>
            <div className='off-canvas__contact'>
              <p>
                11 Solent Ct Norwest
                <br />
                NSW 2153
              </p>
              <p>
                <Link to='tel:+61286609211'>02 8660 9211</Link>
                <br />
                <Link to='mailto:hello@wealthstreetproperty.com.au'>hello@wealthstreetproperty.com.au</Link>
              </p>
            </div>
          </div>
          {data.wp.siteOptions.acf.headerMenu.map((item, index) => (
            <div
              key={index}
              className={`off-canvas__panel${activeOffCanvasPanel === index ? ' active' : ''}`}
            >
              <ul>
                <li onClick={() => this.setState({ activeOffCanvasPanel: null })}>Back To Main</li>
                {item.subMenu &&
                  item.subMenu.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <Link to={subItem.link.url} {...dropProps}>
                        {subItem.link.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default Header;
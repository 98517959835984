import React, { Component } from 'react'
import Link from '../utils/link'
import { FooterLogo } from './icons'
import parse from 'html-react-parser'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <div className='footer__menu'>
              <Link to='/'><FooterLogo /></Link>
              <ul>
                { this.props.data.wp.siteOptions.acf.social.map((item, index) => (
                  <li key={index}><Link to={item.link.url}>{item.link.title}</Link></li>
                )) }
              </ul>
            </div>

            <div className='footer__address'>
              <p>{parse(this.props.data.wp.siteOptions.acf.address)}</p>
              <Link to={this.props.data.wp.siteOptions.acf.phone.link}>{this.props.data.wp.siteOptions.acf.phone.title}</Link>
              <Link to={this.props.data.wp.siteOptions.acf.email.link}>{this.props.data.wp.siteOptions.acf.email.title}</Link>
            </div>
          </div>

          <div className='footer__col'>
            <div className='footer__menu'>
              { this.props.data.wp.siteOptions.acf.footerMenu?.length > 0 && <h3>{this.props.data.wp.siteOptions.acf.footerMenu[0].link.title}</h3> }
              <ul>
                { this.props.data.wp.siteOptions.acf.footerMenu?.length > 0 && this.props.data.wp.siteOptions.acf.footerMenu[0].subMenu?.length > 0 && this.props.data.wp.siteOptions.acf.footerMenu[0].subMenu.map((item, index) => (
                  <li key={index}><Link to={item.link.url}>{item.link.title}</Link></li>
                )) }
              </ul>
            </div>

            <Link className='footer__link' to='/disclaimer/'>Disclaimer</Link>
          </div>

          <div className='footer__col'>
            <div className='footer__menu'>
              { this.props.data.wp.siteOptions.acf.footerMenu?.length > 1 && <h3>{this.props.data.wp.siteOptions.acf.footerMenu[1].link.title}</h3> }
              <ul>
                { this.props.data.wp.siteOptions.acf.footerMenu?.length > 1 && this.props.data.wp.siteOptions.acf.footerMenu[1].subMenu?.length > 0 && this.props.data.wp.siteOptions.acf.footerMenu[1].subMenu.map((item, index) => (
                  <li key={index}><Link to={item.link.url}>{item.link.title}</Link></li>
                )) }
              </ul>
            </div>

            <Link className='footer__link' to='/privacy-policy/'>Privacy Policy</Link>
          </div>

          <div className='footer__col'>
            <div className='footer__menu'>
              { this.props.data.wp.siteOptions.acf.footerMenu?.length > 2 && <h3>{this.props.data.wp.siteOptions.acf.footerMenu[2].link.title}</h3> }
              <ul>
                { this.props.data.wp.siteOptions.acf.footerMenu?.length > 2 && this.props.data.wp.siteOptions.acf.footerMenu[2].subMenu?.length > 0 && this.props.data.wp.siteOptions.acf.footerMenu[2].subMenu.map((item, index) => (
                  <li key={index}><Link to={item.link.url}>{item.link.title}</Link></li>
                )) }
              </ul>
            </div>

            <div className='footer__address'>
              <p>© Wealth Street Property 2024.</p>
              Design by <Link to='https://atollon.com.au'>Atollon</Link>
            </div>
          </div>
          
          <div className='footer__col'>
            <div className='footer__menu'>
              <ul>
                { this.props.data.wp.siteOptions.acf.social.map((item, index) => (
                  <li key={index}><Link to={item.link.url}>{item.link.title}</Link></li>
                )) }
              </ul>
            </div>
          </div>

          <div className='footer__col'>
            <div className='footer__menu'>
              <ul>
                <li><Link to='/disclaimer/'>Disclaimer</Link></li>
                <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              </ul>
            </div>
          </div>

          <div className='footer__col'>
            <div className='footer__address'>
              <p>© Wealth Street 2024</p>
              Design by <Link to='https://atollon.com.au'>Atollon</Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

import React from 'react'

export const Logo = ({ color = '#283C31' }) => (
  <svg width='156' height='12' viewBox='0 0 156 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.7606 10.3333L13.0618 0.166667H14.6014L11.7705 11.8333H9.70113L7.30068 1.85L4.90023 11.8333H2.83088L0 0.166667H1.5396L3.90694 10.3L6.3405 0.166667H8.36019L10.7606 10.3333Z'
          fill={ color }/>
    <path d='M26.792 11.8333H18.2994V0.166667H26.6265V1.53333H19.7893V5.15H25.6332V6.51667H19.7893V10.4667H26.792V11.8333Z' fill={ color }/>
    <path d='M40.5839 11.8333H39.0278L37.8193 8.76667H32.1575L30.949 11.8333H29.426L34.0779 0.166667H35.9651L40.5839 11.8333ZM34.9884 1.55L32.6873 7.4H37.2895L34.9884 1.55Z' fill={ color }/>
    <path d='M52.3568 11.8333H44.0298V0.166667H45.5197V10.4667H52.3568V11.8333Z' fill={ color }/>
    <path d='M57.9762 11.8333H56.4863V1.53333H52.3145V0.166667H62.1481V1.53333H57.9762V11.8333Z' fill={ color }/>
    <path d='M67.2084 11.8333H65.7184V0.166667H67.2084V5.31667H73.3171V0.166667H74.807V11.8333H73.3171V6.68333H67.2084V11.8333Z' fill={ color }/>
    <path
      d='M89.1249 12C86.3768 12 84.6551 10.65 84.6055 8.11667H86.0954C86.0954 9.75 87.3701 10.6333 89.1746 10.6333H89.3401C90.9625 10.6333 91.8399 9.8 91.8399 8.75C91.8399 7.75 91.2108 7.18333 89.9196 6.91667L88.0654 6.53333C86.2444 6.15 85.1849 5.13333 85.1849 3.33333C85.1849 1.5 86.7741 0 89.2739 0C91.8068 0 93.4292 1.4 93.512 3.63333H92.022C91.9227 2.23333 90.8301 1.36667 89.3567 1.36667H89.1912C87.6847 1.36667 86.6748 2.2 86.6748 3.31667C86.6748 4.26667 87.337 4.81667 88.38 5.03333L90.2341 5.4C92.2041 5.8 93.3299 6.83333 93.3299 8.68333C93.3299 10.7333 91.6744 12 89.1249 12Z'
      fill={ color }/>
    <path d='M101.612 11.8333H100.122V1.53333H95.9505V0.166667H105.784V1.53333H101.612V11.8333Z' fill={ color }/>
    <path
      d='M110.844 11.8333H109.354V0.166667H114.255C116.357 0.166667 117.864 1.5 117.864 3.31667C117.864 4.76667 117.069 5.85 116.009 6.21667C117.268 6.21667 117.698 6.66667 117.698 7.83333V11.8333H116.208V8.11667C116.208 7.33333 115.96 7.08333 115.182 7.08333H110.844V11.8333ZM110.844 1.53333V5.71667H114.023C115.414 5.71667 116.374 4.8 116.374 3.53333C116.374 2.25 115.496 1.53333 114.023 1.53333H110.844Z'
      fill={ color }/>
    <path d='M130.85 11.8333H122.357V0.166667H130.684V1.53333H123.847V5.15H129.691V6.51667H123.847V10.4667H130.85V11.8333Z' fill={ color }/>
    <path d='M143.383 11.8333H134.891V0.166667H143.218V1.53333H136.381V5.15H142.225V6.51667H136.381V10.4667H143.383V11.8333Z' fill={ color }/>
    <path d='M151.828 11.8333H150.338V1.53333H146.166V0.166667H156V1.53333H151.828V11.8333Z' fill={ color }/>
  </svg>
)

export const Phone = ({ color = '#FFFFFF' }) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.32646 8.65971L8.1621 9.82334C7.45957 9.36224 6.73084 8.76879 5.98174 8.01826C5.23193 7.26845 4.63774 6.539 4.17593 5.83641L5.34029 4.67278L4.07484 1.52521C3.76648 1.39866 3.42756 1.32739 3.07266 1.32739C1.62686 1.32739 0.45459 2.49903 0.45459 3.94546C0.45459 5.76363 1.25459 7.94537 3.65466 10.3454C6.05474 12.7455 8.23639 13.5455 10.0546 13.5455C11.4997 13.5455 12.6727 12.3724 12.6727 10.9274C12.6727 10.5733 12.6014 10.2343 12.4734 9.9238L9.32646 8.65971Z'
      fill={ color }/>
    <path d='M10.0545 5.98187H11.2181C11.2181 4.21751 9.78252 2.7818 8.01807 2.7818V3.94543C9.14096 3.94543 10.0545 4.85887 10.0545 5.98187Z' fill={ color }/>
    <path d='M12.3818 5.98192H13.5454C13.5454 2.9347 11.0655 0.45459 8.01807 0.45459V1.61822C10.4254 1.61822 12.3818 3.57535 12.3818 5.98192Z' fill={ color }/>
  </svg>
)

export const RightArrow = ({ color = '#FFFFFF' }) => (
  <svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 4.563V3.497H7.605L4.849 0.741L5.577 0L9.62 4.017L5.577 8.06L4.849 7.319L7.592 4.563H0Z' fill={ color }/>
  </svg>
)

export const ThinRightArrow = ({ color = '#FFFFFF' }) => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/s/arrow-right'>
      <g id='Vector'>
        <path fill-rule='evenodd' clip-rule='evenodd' d='M20.6718 12.2058L11.8202 3.61212L12.8532 2.54816L22.8007 12.2058L12.8532 21.8634L11.8202 20.7995L20.6718 12.2058Z' fill={ color }/>
        <path fill-rule='evenodd' clip-rule='evenodd' d='M1.20068 11.4076H21.7337V12.8905L1.20068 12.8905L1.20068 11.4076Z' fill={ color }/>
      </g>
    </g>
  </svg>
)

export const LeftArrow = ({ color = '#FFFFFF' }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/xs/arrow-point-right'>
      <path id='&#226;&#134;&#146;' d='M13 8.563V7.497H5.395L8.151 4.741L7.423 4L3.38 8.017L7.423 12.06L8.151 11.319L5.408 8.563H13Z' fill={color}/>
    </g>
  </svg>
)

export const FilledRightArrow = ({ color = '#FFFFFF' }) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/xs/arrow-right'>
      <path id='Polygon 1' d='M9.3999 6.19618L3.3999 11.3923L3.3999 1.00003L9.3999 6.19618Z' fill='#283C31'/>
    </g>
  </svg>
)

export const FooterLogo = ({ color = '#FFFFFF' }) => (
  <svg width='174' height='46' viewBox='0 0 174 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M41.5435 39.6111L50.4275 0.638888H56.3714L45.4422 45.3611H37.4531L28.1857 7.09167L18.9183 45.3611H10.9291L0 0.638888H5.94392L15.0835 39.4833L24.4787 0.638888H32.2761L41.5435 39.6111Z'
          fill={ color }/>
    <path d='M75.1484 45.3611H67.8623V38.1417H75.1484V45.3611Z' fill={ color }/>
    <path
      d='M107.939 46C97.3292 46 90.6822 40.825 90.4905 31.1139H96.2427C96.2427 37.375 101.164 40.7611 108.131 40.7611H108.77C115.033 40.7611 118.421 37.5667 118.421 33.5417C118.421 29.7083 115.992 27.5361 111.007 26.5139L103.848 25.0444C96.8179 23.575 92.7275 19.6778 92.7275 12.7778C92.7275 5.75 98.8631 0 108.514 0C118.293 0 124.556 5.36667 124.876 13.9278H119.124C118.74 8.56111 114.522 5.23889 108.834 5.23889H108.194C102.378 5.23889 98.4796 8.43333 98.4796 12.7139C98.4796 16.3556 101.036 18.4639 105.063 19.2944L112.221 20.7C119.827 22.2333 124.173 26.1944 124.173 33.2861C124.173 41.1444 117.781 46 107.939 46Z'
      fill={ color }/>
    <path d='M157.894 45.3611H152.142V5.87778H136.036V0.638888H174V5.87778H157.894V45.3611Z' fill={ color }/>
  </svg>
)

export const Calendar = ({ color = '#FFFFFF' }) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/s/calendar'>
      <g id='Group'>
        <path id='Vector'
              d='M5.57929 0.900024V2.12557H1.80289V17.1H16.1966L16.1973 2.12557H12.4209V0.900024H11.4949V2.12557H6.50546V0.900024H5.57929ZM15.2712 16.1739H2.72881V6.97215H15.2712V16.1739ZM11.4948 3.05084V4.01694H12.4208V3.05084H15.2713V6.04623H2.7289V3.05084H5.57938V4.01694H6.50539V3.05084H11.4948Z'
              fill={ color }/>
      </g>
    </g>
  </svg>
)

export const LocationMark = ({ color = '#FFFFFF' }) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='icons/s/location'>
      <path id='Vector'
            d='M9.00001 2.47998C6.43141 2.47998 4.32001 4.4832 4.32001 7.33998C4.32001 8.35952 4.56047 9.06897 5.00626 9.8544L8.6851 16.3344C8.74838 16.449 8.86931 16.52 9.0001 16.52C9.13089 16.52 9.25181 16.449 9.3151 16.3344L12.9939 9.8544C13.4397 9.06901 13.6802 8.35956 13.6802 7.33998C13.6802 4.4832 11.5686 2.47998 9.00001 2.47998ZM9.00001 4.99998C10.1932 4.99998 11.16 5.96678 11.16 7.15998C11.16 8.35318 10.1932 9.31998 9.00001 9.31998C7.80681 9.31998 6.84001 8.35318 6.84001 7.15998C6.84001 5.96678 7.80681 4.99998 9.00001 4.99998Z'
            fill={ color }/>
    </g>
  </svg>
)


export const Search = ({ color = '#283c31' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.8167 11.8167C14.1753 9.45926 14.1753 5.63588 11.8167 3.27865C9.45931 0.920062 5.63597 0.920062 3.27876 3.27865C0.920193 5.63607 0.920193 9.45944 3.27876 11.8167C5.63615 14.1753 9.45949 14.1753 11.8167 11.8167ZM12.325 13.3922C9.36029 15.8204 4.97805 15.6518 2.21027 12.8839C-0.736756 9.9369 -0.736756 5.15834 2.21027 2.21029C5.15847 -0.736763 9.93701 -0.736763 12.8838 2.21029C15.6516 4.9781 15.8202 9.36048 13.3921 12.3251L17.9996 16.9327L16.9324 18L12.325 13.3922Z" fill={color} />
  </svg>
)

